import React from "react";
import { Navbar } from "./Components/Navbar";
import Header from "./Components/Header";
import About from "./Components/About";
import Contant from "./Components/Contant";
import Text from "./Components/Text";
import Project from "./Components/Project";
import Blog from "./Components/Blog";
import Classroom from "./Components/Classroom";
import Partners from "./Components/Partners";
import Footer from "./Components/Footer";
import { Helmet } from "react-helmet";

const School = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>Education || School </title>
      </Helmet>

      <div>
        <Navbar />
        <Header />
        <About />
        <Contant />
        <Text />
        <Project />
        <Blog />
        <Classroom />
        <Partners />
        <Footer />
      </div>
    </>
  );
};
export default School;
