import { BrowserRouter, Routes, Route } from "react-router-dom";

import Choching from "./Choching/Choching";
import Education from "./Education";
import School from "./School/School";
import Collage from "./Collage/Collage";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Education />} />
          <Route path="/coaching-classes-online" element={<Choching />} />
          <Route path="/online-education" element={<Collage />} />
          <Route path="/top-international-school" element={<School />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
