import React from 'react'
import logo from '../images/brain.png'

export const Navbar = () => {
  return (
    <div>
        
    <div className="flex items-center justify-evenly">
        <div className="flex items-center w-[200px] justify-between">
          <div className="w-12">
            <img src={logo} className=''  alt=""/>
          </div>
           <h3 className='text-lg'> Cerbosys School</h3>
        </div>
        <div className="w-[700px] md:block hidden" >

    <ul className='flex justify-evenly '>
     <li>Home</li>
     <li>About</li>
     <li>Facilities</li>
     <li>Blog</li>
     <li>Admission</li>
    <li>Alumini</li>
    <li>Shop</li>
    <li>Contact</li>


    </ul>
        </div>
    </div>
    <div className="w-full bg-cover min-h-[80vh]  mb-10 md:bg-hero3 bg-hero5">


</div>
    </div>
  )
}
