import React from 'react'
import btn1 from '../images/btn1.png'
import btn2 from '../images/btn2.png'
import btn3 from '../images/btn3.png'
import child from '../images/childimage.png'
 const Contant = () => {
  return (
    <div>
<div className="md:h-[70vh] w-full bg-[#5F5AF0] flex md:justify-around md:flex-row flex-col justify-center items-center h-auto ">

<div className="w-[80%] flex flex-col text-[white] justify-center items-center">
    <h1 className='font-bold text-4xl md:w-1/2 mb-14 mt-10'>Why Our Schools are the Right Fit for Your Child?</h1>
    <p className='md:w-1/2'>Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet.</p> 
    <div className='text-center md:w-1/2 mb-10'>
  <button className="w-full h-[50px] flex items-center gap-5 mt-2.5 rounded-[20px] border-[none] bg-white px-4">
    <img className='flex justify-start'  src={btn1}   alt=""/>
  <span className='text-black'> Well Trained Professionals</span> 
  </button>
  <button className="w-full h-[50px] flex items-center gap-5 mt-2.5 rounded-[20px] border-[none] bg-white px-4">
    <img className='flex justify-start'  src={btn2}   alt=""/>
  <span className='text-black'>  Awesome Infra-Structure
    </span> 
  </button>
  <button className="w-full h-[50px] flex items-center gap-5 mt-2.5 rounded-[20px] border-[none] bg-white px-4">
    <img className='flex justify-start'  src={btn3}   alt=""/>
  <span className='text-black'>  International Lesson Patterns
    </span> 
  </button>

  </div>
</div>
<div className=" mt-2.5 ">
 <div className="img">
    <img src={child}  alt=""/>
 </div>
</div>
</div>

    </div>
  )
}
export default Contant;