import React from 'react'
import aone from '../images/aone.png'
import atwo from '../images/atwo.png'
import athree from '../images/athree.png'

import afour from '../images/afour.png'

 const Text = () => {
  return (
    <div>
         <div className="flex md:justify-evenly w-full mt-5  md:flex-row flex-col justify-center items-center h-auto">

<div className="w-[40%] md:pl-24">
    <div className="flex md:flex-row flex-col gap-[15px]">
        <div className="rounded-[20px]">
            <img src={aone}  alt=""/>
        </div>
        <div className="h-4/5 md:mt-24 rounded-[20px]">
            <img src={atwo} alt="" />
        </div>
    </div>
    <div className="flex md:flex-row flex-col gap-[15px] mt-2.5 ">
        <div className="pl-[10%] rounded-[20px]">
            <img src={athree} alt=""/>
        </div>
        <div className=" h-4/5 rounded-[20px]">
            <img src={afour}  alt=""/>
        </div>
    </div>
</div>
<div className="flex flex-col md:w-2/5 pt-5 pb-[30px]">
    <h1 className='font-bold text-4xl'>Ask About Kids Acts</h1>
    <input className='h-[50px] mt-5 pl-5 border-[none]' placeholder="Your Name" type="text"/>
    <input className='h-[50px] mt-5 pl-5 border-[none]' placeholder="Email Address" type="text"/>
    <input className='h-[50px] mt-5 pl-5 border-[none]' placeholder="Phone Number" type="text"/>
    <input className='h-[50px] mt-5 pl-5 border-[none]' placeholder="Study className" type="text"/>
    <input className='h-[50px] mt-5 pl-5 border-[none]' placeholder="Type Your Requirements" type="text"/>
    <button className='h-10 bg-[#F0AA00] text-[white] mt-[25px] rounded-[20px] border-[none] hover:bg-red-600'>Submit now</button>
</div>
</div>
    </div>
  )
}
export default Text;