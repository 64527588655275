import React from 'react'
import one from '../images/one1.png'
import two from '../images/two2.png'
import three from '../images/three3.png'
import four from '../images/four4.png'
import five from '../images/five5.png'
import six from '../images/six6.png'
import squ1 from '../images/sqaure1.png'
import squ2 from '../images/sqaure2.png'
import squ3 from '../images/sqaure3.png'

import sqa4 from '../images/sqaure4.png'

const Blog = () => {
  return (
    <div>
        <div className="flex md:justify-evenly md:flex-row flex-col justify-center items-center  w-full">
        {/* items-center  md:items-end */}
        <div className="md:w-[40%] w-[100%] p-4 pt-10">
            <h1 className='text-3xl font-bold mb-6' >Clients Testimonials</h1>
            <p className='font-medium'>Cum sociis natoque penatibus et magnis dis parturient montes. nascetur ridiculus mus. rhoncus ut, imperdiet a, venenatis vitae, justo. Aliquam lorem ante natoque penatibus et magnis dis parturient nascetur ridiculus mus</p>
            <div className="w-full h-[150px] bg-[#F1AA00] text-[white] md:mt-[100px] mt-10 p-[15px] rounded-[10px]">
              <h2 className='text-2xl font-semibold mb-4'>John Cena</h2>
              <p className='text-sm'>In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.</p>
            </div>
        </div>
        <div className="flex flex-col md:w-2/5">
            <div className="flex">
                <div><img src={one} alt=""/></div>
                <div><img src={two} alt=""/></div>
                <div><img className="mt-[15%]" src={three}  alt=""/></div>
            </div>
<div className="flex gap-[10px]">

    <div><img src={four}  alt=""/></div>
    <div><img className="mt-[15%]" src={five} alt=""/></div>
    <div><img src={six}  alt=""/></div>
    
</div>
        </div>
    </div>
    <div className="flex md:justify-evenly justify-center items-center flex-col md:flex-row relative mt-[50px] ">

        <div className="md:mb-4 mb-20">
           <img src={squ1}  alt=""/>
           <div className="md:ml-[180px] ml-[100px]">
           
            <h1 className='text-2xl font-semibold'>2453</h1>
            <h5 className='text-sm font-medium'>Stuent Passed</h5>
      
    </div>
        </div>
        <div className="md:mb-4 mb-20">
            <img src={squ2} alt=""/>
            
        <div className="md:ml-[180px] ml-[100px]">
           
            <h1 className='text-2xl font-semibold'>678</h1>
            <h5 className='text-sm font-medium'>Qualified Staffs</h5>
      
    </div>
        </div>
        <div className="md:mb-4 mb-20">
            <img src={squ3} alt=""/>
            <div className="md:ml-[180px] ml-[100px]">
           
                <h1 className='text-2xl font-semibold'>52</h1>
                <h5 className='text-sm font-medium'>Courses in Trails</h5>
          
        </div>
        </div>
        <div className="md:mb-4 mb-20">
            <img src={sqa4} alt=""/>
            <div className="md:ml-[180px] ml-[100px]">
           
                <h1 className='text-2xl font-semibold'>936</h1>
                <h5 className='text-sm font-medium'>Equipments</h5>
            
            </div>
        </div>

    </div>
    </div>
  )
}
export default Blog;