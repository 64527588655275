import React from 'react'
import logo from '../images/footerlogo.png'
import fb from '../images/fb.png';
import social from '../images/social.png'
import twitter from '../images/twitter.png'
import child from '../images/childgroup.png'
import sport from '../images/sportchild.png'

const Footer = () => {
  return (
    <div>
         <div className="md:h-[60vh] w-full flex justify-center items-center text-[white] bg-hero7 bg-cover h-fit">
    <div className="flex justify-evenly mt-[60px]  md:flex-row flex-col h-fit">

        <div className="md:w-1/3 text-[small] w-full flex  flex-col h-fit mt-16 pl-[10%]">
           
            <h2 className='h-fit w-fit text-xl font-medium'> <span><img src={logo} alt=""/></span> Cerbosys School</h2>
            <p>CERBOSYS is a software development company that provides customized and creative services for online businesses. The services.....</p>
            
            <div className="flex gap-[5px]">
                <div><img src={fb}  alt=""/></div>
                <div><img src={social}  alt=""/></div>
                <div><img src={twitter}  alt=""/></div>
            </div>

        </div>
        <div className="md:w-1/3 text-[small] w-full flex flex-col h-fit md:mt-20 mt-10 pl-[10%] mb-10">
            <h2 className='h-fit w-fit text-xl font-medium '>About School</h2>
            <ul className="leading-7 pl-4">

<li>General Information</li>
<li>Who We are</li>
<li>Our Teachers</li>
<li>School History</li>
<li>Specialized Programie</li>
<li>Language Training</li>


            </ul>
        </div>
        <div className="md:w-1/3 text-[small] w-full flex flex-col h-fit md:mt-20 pl-[10%] mb-10">
            <h2 className='h-fit w-fit text-xl font-medium'>Recent Posts</h2>

            <div className="flex gap-2.5 mt-[5px] pl-4"><img  src={child} alt=""/>
            <p className='md:pt-1 pt-6'>Reading practice for
                the students</p></div>
            <div className="flex gap-2.5 mt-[5px] pl-4"><img   src={sport} alt=""/>
            <p  className='md:pt-1 pt-6'>A team dedicated to
                football matches</p></div>
        </div>
        <div className="md:w-1/3 text-[small] w-full flex flex-col h-fit md:mt-20 pl-[10%] mb-10">
            <h2 className='h-fit w-fit text-xl font-medium'>Keep in Touch</h2>
            <p className='mb-2 pl-4' >Our school is the perfect
                environment for language studies</p>
                <p className='mb-2 pl-4'>79200 Iskandar Puteri Malaysia
                    mattis</p>
                    <p className='pl-4'> support@domain.com
                    </p>
        </div>
    </div>
    </div>
    <div className="h-[60px] flex justify-center items-center text-[white] bg-[#433ED2]">
        <p>© 2020 Cerbosys All Rights Reserved</p>
    </div>
    </div>
  )
}
export default Footer;