import React from 'react'
import gallery1 from '../images/gallery1.png'
import gallery2 from '../images/gallery2.png'
import gallery3 from '../images/gallery3.png'
import gallery4 from '../images/gallery4.png'
function Imagegallery() {
  return (
    
   <div className="mt-10">
   <div className="flex md:justify-evenly  flex-wrap ">
    <div className="md:mb-1 mb-10">   
        <img src={gallery1} alt=""/>
    </div>
    <div className="md:mb-1 mb-10">   
        <img src={gallery2} alt=""/>
    </div>
   </div>
   <div className="flex md:justify-evenly mt-2  flex-wrap">
    <div className="md:mb-1 mb-10">   
        <img src={gallery3} alt=""/>
    </div>
    <div className="md:mb-1 mb-10">   
        <div><img src={gallery4} alt=""/></div>
    </div>
   </div>
   </div>
  )
}

export default Imagegallery