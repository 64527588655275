import React from 'react'
import euro from '../images/euroschool.png'
import ridge from '../images/ridge.png'
import kidsedu from '../images/kidsedu.png'
import lion from '../images/lion.png'

 const Partners = () => {
  return (
    <div className='py-10'>
 <div className="text-center ">
        <h1 className='text-4xl font-bold pb-4'>Our Partners</h1>
        <p className='md:text-lg text-sm'>Aliquam lorem ante, dapibus in, viverra quis, feugiat a, telle.</p>
    </div>
    <div className="w-full flex justify-evenly md:mt-[70px] mt-4 m-0 p-0">
        <div className="">
            <img  src={euro} alt=""/>
        </div>
        <div className="">
            <img  src={ridge} alt=""/>
        </div>

        <div className="">
            <img  src={kidsedu} alt=""/>
        </div>
        <div className="">
            <img  src={lion} alt=""/>
        </div>
    </div>
    <div className="flex md:justify-around mt-[30px] justify-center items-center">
        <div className="w-[600px] md:mt-[180px] pl-[2%]">
            <h1 className='md:text-4xl text-3xl font-bold mb-4'>New World, New Education It's Small World..!</h1>
            <p>  <p>New Course Starts in <span className="text-[#8700FF]">56 minutes 12 second</span></p></p>
        </div>
        <div className="bg-cover h-[30vh] w-[27%] mt-20 md:block hidden bg-hero6">
           
        </div>

    </div>

    </div>
  )
}
export default Partners;