import React from 'react';
import fb from '../images/fb-icon.png.png';
import twitter from '../images/twitter-icon.png.png';
import instagram from '../images/instagram-icon.png.png';
import linkedin from '../images/linkedin-icon.png.png';
import brain from '../images/brain.png';
function Navbar() {
  return (
    <div className="flex items-center justify-evenly pt-[5px]">
      <div className="logo">
        <img src={brain} alt="" />
      </div>
      <div className=" w-[500px] justify-between md:block hidden ">
        <ul className='flex flex-wrap gap-8'>
        <li>HOME</li>
        <li>ABOUT</li>
        <li>SERVICES</li>
        <li>BLOG</li>
        <li>CONTACT US</li>
        </ul>
      </div>
      <div className="flex w-[150px] items-center justify-evenly">
     
       
          <div>
            <img src={fb} alt="" />
          </div>
    
        <div>
          <img src={twitter} alt="" />
        </div>
  
    
          <div>
            <img src={linkedin} alt="" />
          </div>
     
     
       
          <div>
            <img src={instagram} alt="" />
          </div>
      
      </div>
    </div>
  );
}

export default Navbar;
