import React from 'react'
import food from '../images/food.png'
import book from '../images/book.png'
import search from '../images/search.png'
const About = () => {
  return (
    <div>
 <div className="text-center pt-10">

<h1 className='font-bold text-4xl'>Standard Curriculum</h1>

</div>
<div className="flex items-center justify-between mt-[50px] md:flex-row flex-col">
<div>
<div className="flex justify-center items-center">
<img src={food} alt=""/>
</div>
<div className="text-center">
<h1 className='text-2xl font-bold mb-4'>Kinder (3-6) Years</h1>
<p>  Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mu sdis parturient</p>

<h5 className='text-lg font-semibold hover:text-red-600 mb-10'>Read More</h5>

</div>


</div>
<div>
    <div className="flex justify-center items-center"><img src={book} alt=""/></div>
    <div className="text-center ">
        <h1 className='text-2xl font-bold mb-4'>Elemantry School</h1>
        <p>  Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mu sdis parturient</p>

        <h5 className='text-lg font-semibold hover:text-red-600 mb-10'>Read More</h5>
    </div>
  
  
          </div>
          <div>
            <div className="flex justify-center items-center">
                <img src={search} alt=""/>
            </div>
            <div className="text-center">
             
        <h1 className='text-2xl font-bold mb-4'>Middle (10-16) Years</h1>
                <p>  Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mu sdis parturient</p>

                <h5 className='text-lg font-semibold hover:text-red-600 mb-10' >Read More</h5>
            </div>
          
          
                  </div>
</div>

    </div>
  )
}
export  default About;