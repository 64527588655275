import React from 'react'
import circle1 from '../images/circleone.png'
import circle2 from '../images/circletwo.png'
import circle3 from '../images/circlethree.png'
import circle4 from '../images/circlefour.png'

 const Header = () => {
  return (
    <div>
 <div className="bg-blue-400 flex items-center pt-24  pb-24 justify-evenly  md:flex-row  flex-col ">

<div className="flex justify-center items-center flex-col pb-10">

    <div className="w-24">
        <img src={circle1}  alt=""/>
    </div>
    <div><h2>Our Team</h2></div>
</div>

<div className="flex justify-center items-center flex-col pb-10">

    <div className="w-24">
        <img src={circle2} alt=""/>
    </div>
    <div><h2>Kids Enrollment</h2></div>
</div>
<div className="flex justify-center items-center flex-col pb-10">
    <div className="w-24">
        <img src={circle3}  alt=""/>
    </div>
    <div><h2>Alumini Club</h2></div>
</div>
<div className="flex justify-center items-center flex-col pb-10">
    <div className="w-24">
        <img src={circle4} alt=""/>
    </div>
    <div><h2>Best Amenities</h2></div>
</div>
    </div>

    </div>
  )
}
export default Header;