import React from 'react'
import Librarypicture from '../images/librarypicture.png'

function Blog() {
  return (
    <>
 <div className="text-center py-10">
 <h1 className='text-3xl font-bold mb-4'>Our Bolg</h1>
 <p>Do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
</div>
<div className="flex md:justify-between md:flex-row flex-col justify-center p-[2%] border-gray-300 border-2">
{/* flex flex-col justify-center items-center h-fit */}
    <div className="md:w-6/12 flex justify-center items-center"><img src={Librarypicture} alt=""/></div>
    <div className="md:w-[48%] flex flex-col justify-center  w-full  mt-2.5">
        <h1 className='font-bold md:text-4xl mb-4 text-2xl'>Best classNamees and study</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</p>
    </div>
   </div>
   <div className="mt-8 pl-4">
    <button className="bg-[#FCCE2D] h-[35px] w-[120px] text-[white] font-[lighter] rounded-[20px] border-[none] hover:bg-black ">Read More</button>
   </div>
</>
  )
}

export default Blog