import React from 'react'
import main from '../images/mainimg.png'
import right1 from '../images/right1.png'
import right2 from '../images/right2.png'
import right3 from '../images/right3.png'
import right4 from '../images/right4.png'

 const classroom = () => {
  return (
    <div>

<div className="bg-[#BAE7FF] h-auto flex md:flex-row flex-col  md:mt-[100px] p-[5%]">
{/* h-auto flex flex-col justify-center items-center */}
        <div className="flex  md:flex-row flex-col md:gap-2.5">

<div className="flex flex-col md:pl-40 pt-20 gap-2.5">
    <img className='md:w-[80%] w-[100%] ' src={main} alt=""/>
<div className="bg-[white] p-[5%] md:w-6/7">
    <h2 className='text-2xl font-bold mb-6'>A fun toddler coloring training on
        classroom</h2>
        <p>Beautiful branding for changes by Never Now in Australia.
            changes is a platform for open discussion in an inclusive and
            collaborative environment, providing the.</p>
</div>
</div>
<div className="flex md:flex-wrap md:flex-row flex-col justify-center items-center  md:p-20  gap-[5px]">
    <div className="w-[260px] gap-2.5 bg-white">
        <img src={right1}  alt=""/>
        <div className="text-justify w-4/5 p-[5%]">
            <h4 className='font-semibold'>Find out if a school fits the
                family's needs</h4>
        </div>
    </div>
    <div className="w-[260px] gap-2.5 bg-white">
        <img src={right2}  alt=""/>
        <div className="text-justify w-4/5 p-[5%]">
            <h4 className='font-semibold'>Summer math & literacy
                centres for kids</h4>
        </div>
    </div>
    <div className="w-[260px] gap-2.5 bg-white"><img src={right3}  alt=""/>
        <div className="text-justify w-4/5 p-[5%]">
            <h4 className='font-semibold'>classroom management
                techniques for new tutors</h4>
        </div></div>
    <div className="w-[260px] gap-2.5 bg-white"><img src={right4}  alt=""/>
        <div className="text-justify w-4/5 p-[5%]">
            <h4 className='font-semibold'>Conversation rhymes
                improve kids listening</h4>
        </div></div>
</div>

        </div>
    </div>
    </div>
  )
}
export default  classroom;