import React from 'react'
import Map from '../images/map-img.png.png'
import Book from '../images/book.png'
function Consulting() {
  return (
    <>
    
    <div className="flex items-center text-[xx-large] justify-between mt-[15px] pt-[5px] px-[2%]">

    <div className="md:text-3xl text-lg font-semibold" >
        Get
        <br/>
        Your free consutings
    </div>
    <div className="right_button"><button className="md:w-[190px] w-40 h-[39px] bg-[black] text-[white] rounded-[20px]">Get a Quote</button></div>
   </div>
  <div className="flex justify-center items-center p-[2%] md:text-base text-sm"> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p></div>
    <div className="flex md:flex-row flex-col items-center gap-10">
      <div className="book_image"><img src={Book} width="100%" alt=""/></div>
      <div className="map_image"><img src={Map}  width="100%" alt=""/></div>
    </div>
    </>
  )
}

export default  Consulting;