import React from 'react'
import testimonial from '../images/testimonialimage.png'
function Testimonial() {
  return (
    <div className="bg-hero2 bg-cover md:h-[60vh] mt-10 flex flex-col md:justify-center items-center text-white">
  

    <div className="text-center">  <h1 className='text-4xl font-bold md:mt-1 mt-6 md:mb-14'>Testimonial</h1></div>
    <div className="w-[50%] flex mt-10 md:flex-row flex-col  ">
        <div className="w-full">
            <img src={testimonial} alt=""/>
        </div>
        <div className="flex flex-col justify-center pl-4">
            <h1 className='md:text-4xl text-3xl pb-6 font-bold'>Jony Deno</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>
        </div>
    </div>
    
      
       </div>
  )
}

export default Testimonial