import React from 'react'

function OurCourse() {
  return (
    
   <div class="flex justify-center items-center py-[2%] ">
   <h1 className='border-b-2 border-[#FCCE2D] hover:border-black font-bold text-4xl'><span class="text-[#FCCE2D]">Our</span> Courses</h1>
  </div>
  )
}

export default OurCourse