import React from 'react'
import john from '../images/johnimage.png'
import lida from '../images/lidajoans.png'
import michle from '../images/michle.png'
import lilly from '../images/lilly.png'

 const Project = () => {
  return (
    <div>
 <div className="bg-[#A5C347] text-center text-[white] md:p-[100px] p-10 ">

        <div className="text-center">
            <h1 className='font-bold text-4xl'>Our Qualified Staffs</h1>
        </div>

        <div className="flex md:justify-evenly justify-center md:flex-row flex-col text-center gap-5 mt-5">

<div>
    <div className="mb-4">
        <img src={john} alt=""/>
    </div>
    <div className="textarea">
        <p>Instructor / Teacher</p>
        <h1 className='my-4 text-3xl font-bold'>John Parker (Phd)</h1>
        <button className='h-[30px] text-center w-[150px] rounded-[10px] border-[none] bg-white text-black'>0987654321</button>
    </div>
</div>
<div>
    <div className="mb-4">
        <img src={lida} alt=""/>
    </div>
    <div className="text-center">
        <p>Instructor / Teacher</p>
        <h1 className='my-4 text-3xl font-bold'>Lida Joans</h1>
        <button className='h-[30px] text-center w-[150px] rounded-[10px] border-[none] bg-white text-black'>0987654321</button>
    </div>
</div>
<div>
    <div className="mb-4">
        <img src={michle} alt=""/>
    </div>
    <div className="text-center">
        <p>Instructor / Teacher</p>
        <h1 className='my-4 text-3xl font-bold'>Michel Joarden</h1>
        <button className='h-[30px] text-center w-[150px] rounded-[10px] border-[none] bg-white text-black'>0987654321</button>
      
    </div>
</div>
<div>
    <div className="mb-4">
        <img src={lilly} alt=""/>
    </div>
    <div className="text-center">

        <p>Instructor / Teacher</p>
        <h1 className='my-4 text-3xl font-bold'>Lilly Thomas</h1>
        <button className='h-[30px] text-center w-[150px] rounded-[10px] border-[none] bg-white text-black'>0987654321</button>
    </div>
</div>



        </div>
    </div>

    </div>
  )
}
export default Project;