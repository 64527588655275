import React from 'react'
import Navbar from './Components/Navbar'
import Header from './Components/Header'
import OurCourse from './Components/OurCourse'
import Imagegallery from './Components/Imagegallery'
import About from './Components/About'
import Blog from './Components/Blog'
import Testimonial from './Components/Testimonial'
import Consulting from './Components/Consulting_text'
import Footer from './Components/Footer'
import { Helmet } from 'react-helmet'

const Choching = () => {
  return (
    <>
       <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>Education || Choching </title>
      </Helmet>
    
    <div>
        <Navbar/>
        <Header/>
        <OurCourse/>
        <Imagegallery/>
        <About/>
        <Blog/>
        <Testimonial/>  
        <Consulting/>
        <Footer/>
    </div></>
  )
}
export  default Choching