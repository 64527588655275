import React from 'react'
import rightbook from '../images/rightbook.png'

function About() {
  return (
    <>
    <div className="text-center py-10">
    <h1 className='text-3xl font-bold mb-4'>Our About</h1>
    <p>Do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
   </div>
     <div className="flex md:justify-evenly md:flex-row flex-col justify-center ">
     {/* flex-col justify-center items-center */}
     <div className="md:w-[47%]  py-[5%]">
 
         <p className='py-6 '>01 Jan 2045</p>
         <h1 className='font-bold text-4xl pb-4'>Make It Simple</h1>
         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p>
     </div>
     <div className="flex justify-center items-center">
         <img src={rightbook} alt=""/>
     </div>
    </div>
    </>
  )
}

export default About