import React from 'react'
import Cerbosys from '../images/cerbosys.png'   
function Footer() {
  return (
    <div className="bg-[rgba(0,0,0,0.842)] pt-5 pb-[50px]">
    <div className="flex md:flex-row flex-col justify-center items-center pb-10  md:justify-evenly">
        <button className="text-sm  rounded-2xl mb-4  bg-[#FCCE2D] md:px-28 px-8 py-2">SUBSCRIBE</button>
        <input className="h-10 text-[black] w-6/12 rounded-[25px] text-center border-2 border-black" placeholder="Enter Your Email" type="text"/>
    </div>


    <div className="flex md:justify-evenly flex-col md:flex-row text-[white]  justify-center items-center   ">

        <div className="flex flex-col justify-center mb-10">
            <p className='mb-6'>Call Now +01 9876543210</p>
            <p>mail@domain.com</p>
        </div>
        <div>
            <h4 className='text-2xl font-bold text-[#FCCE2D] ' >Information</h4>
            <ul className='leading-loose mb-10'>
            <li>there are many</li>
            <li>varitaions of</li>
            <li>Passages of lorem</li>
            <li>ipsem availbale but</li>
            <li>majority have sufferd</li>
            <li>sufferd allternation</li>
            </ul>
     
        </div>
        <div>
            <h4 className='text-[#FCCE2D] font-bold text-2xl'>Helpful Links</h4>
            <ul className='leading-loose mb-10'>
            <li>Home</li>
            <li>About</li>
            <li>Services</li>
            <li>Blog</li>
            <li>News</li>
            </ul>
        </div>
        <div className="flex flex-col justify-center">
            <img src={Cerbosys} alt=""/>
        </div>
    </div>
  </div>
  )
}

export default Footer