import React from 'react'
import Client from '../Collage/Components/Client';
import Navbar from '../Collage/Components/Navbar';
import Hero from '../Collage/Components/Hero';
import Feature from '../Collage/Components/Feature';
import Footer from '../Collage/Components/Footer';
import Events from '../Collage/Components/Events';
import Trainers from '../Collage/Components/Trainers';
import Courses from '../Collage/Components/Courses';
import Register from  '../Collage/Components/Register'
import { Helmet } from 'react-helmet';
 const Collage = () => {
  return (
    <>
    
    <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>Education || College </title>
      </Helmet>
    

    <div>
      <Navbar/>
      <Hero/>
      <Feature/>
      <Courses/>
      <Register/>
      <Trainers/>
      <Events/>
    <Client/>
    <Footer/>
    </div>
    </>
  )
}

export default Collage;