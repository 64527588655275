import React from 'react'

function Header() {
  return (
    <div className='pt-10'>
    <div className="w-full h-[70vh] bg-center text-[white] flex items-center text-[larger] pl-[5%] bg-hero1 ">
<div className="max-w-[500px] min-w-[300px] md:pl-20 ">
    <h1 className='text-5xl font-bold pb-6'>Casinal Educations <br/>  Coachings</h1>
    <p className='text-lg font-medium'>Eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
    <button className="bg-[#fcce2d] h-10 w-[140px] mt-[15px] rounded-[40px] border-[none] hover:bg-black hover:text-white">About us</button> <button className="bg-[rgba(0,0,0,0.897)] h-10 w-[140px] text-[white] ml-[5px] rounded-[40px] border-[none] hover:bg-[#fcce2d] hover:text-black">Get A Quote</button>
</div>
   </div> 
   </div>
  )
}

export default Header;
